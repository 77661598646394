
<template>
 <vx-card>
  <form-wizard color="rgba(var(--vs-primary), 1)" title="Enregistrez un billetage" :subtitle="null" finishButtonText="Enregistrer" @on-complete="formSubmitted">
      <tab-content title="" class="mb-5">

        <!-- tab 1 content -->
        <div class="vx-row">
            <div class="vx-col md:w-1/3 w-full mt-5">
                <vs-input label="IdOfficine" v-model="IdOfficine" class="w-full" />
            </div>
            <div class="vx-col md:w-1/3 w-full mt-5">
                <vs-input label="IdUser"  v-model="IdUser" class="w-full" />
            </div>
            <div class="vx-col md:w-1/3 w-full mt-5">
                <span>
                    Date de cloture
                </span>
                <datepicker :language="languages[language]" format="dd MMMM yyyy" v-model="DateCloture" class="w-full" ></datepicker>
            </div>
        </div>
        <div class="vx-row">
            <div class="vx-col md:w-1/2 w-full mt-8">
                <vs-input-number label="Mont 10.000 :" v-model="MontDixMil" class="w-full" />
            </div>
            <div class="vx-col md:w-1/2 w-full mt-8">
                <vs-input-number label="Mont 5.000 :"  v-model="MontCqMil" class="w-full" />
            </div>
            
        </div>
      </tab-content>

      <tab-content class="mb-5">
          <!-- tab 2 content -->
          <div class="vx-row">
            <div class="vx-col md:w-1/2 w-full mt-5">
                <vs-input-number label="Mont 2.000 :" v-model="MontDeuxMil" class="w-full" />
            </div>

            <div class="vx-col md:w-1/2 w-full mt-5">
                <vs-input-number label="Mont 1.000 :"  v-model="MontMil" class="w-full" />
            </div>
            <div class="vx-col md:w-1/2 w-full mt-5">
                <vs-input-number label="Mont 500 :"  v-model="MontCqCnt" class="w-full" />
            </div>
            <div class="vx-col md:w-1/2 w-full mt-5">
                <vs-input-number label="Mont 250 :" v-model="MontDeuxCntCqt" class="w-full" />
            </div>
            <div class="vx-col md:w-1/2 w-full mt-5">
                <vs-input-number label="Mont 200 :" v-model="MontDeuxCnt" class="w-full" />
            </div>
            <div class="vx-col md:w-1/2 w-full mt-5">
                <vs-input-number label="Mont 100 :" v-model="MontCnt" class="w-full" />
            </div>
            <div class="vx-col md:w-1/2 w-full mt-5">
                <vs-input-number label="Mont 50 :" v-model="MontCqt" class="w-full" />
            </div>
            <div class="vx-col md:w-1/2 w-full mt-5">
                <vs-input-number label="Mont 25 :" v-model="MontVingtCinq" class="w-full" />
            </div>
            <div class="vx-col md:w-1/2 w-full mt-5">
                <vs-input-number label="Mont 10 :" v-model="MontDix" class="w-full" />
            </div>
            <div class="vx-col md:w-1/2 w-full mt-5">
                <vs-input-number label="Mont 5 :" v-model="MontCinq" class="w-full" />
            </div>

          </div>
      </tab-content>
        <!-- tab 3 content -->
      <tab-content title="" class="mb-5">
          <div class="vx-row">
            <div class="vx-col md:w-1/2 w-full mt-5">
                <vs-input type="number" label="Total En caisse" v-model="TotalEncaise" class="w-full" />
            </div>
            <div class="vx-col md:w-1/2 w-full mt-5">
                <vs-input label="ObservaTion" v-model="ObservaTion" class="w-full" />
            </div>
            <div class="vx-col md:w-1/2 w-full mt-5">
                <vs-input-number label="MontCheqRecu" v-model="MontCheqRecu" class="w-full" />
            </div>
            <div class="vx-col md:w-1/2 w-full mt-5">
                <vs-input-number label="MontCeqEmis" v-model="MontCeqEmis" class="w-full" />
            </div>
          </div>
      </tab-content>

  </form-wizard>
 </vx-card>

</template>

<script>
import {FormWizard, TabContent} from 'vue-form-wizard'
import {BFormFile} from 'bootstrap-vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import Datepicker from 'vuejs-datepicker'
import * as lang from 'vuejs-datepicker/src/locale'


export default {
  components: {
    BFormFile,
    Datepicker,
    FormWizard,
    TabContent
  },
  data () {
    return {
      language: 'fr',
      languages: lang,

      IdOfficine: '',
      IdUser:'',
      DateCloture:'',
      MontDixMil:0,
      MontCqMil:0,
      MontDeuxMil:0,
      MontMil:0,
      MontCqCnt:0,
      MontDeuxCntCqt:0,
      MontDeuxCnt:0,
      MontCnt:0,
      MontCqt:0,
      MontVingtCinq:0,
      MontDix:0,
      MontCinq:0,
      TotalEncaise: 0,
      ObservaTion: '',
      MontCheqRecu: 0,
      MontCeqEmis: 0
    }
  },
  methods: {
    formSubmitted () {
      const payload = {
        IdOfficine: this.IdOfficine,
        IdUser: this.IdUser,
        DateCloture: this.DateCloture,
        MontDixMil: this.MontDixMil,
        MontCqMil: this.MontCqMil,
        MontDeuxMil: this.MontDeuxMil,
        MontMil: this.MontMil,
        MontCqCnt: this.MontCqCnt,
        adresse: this.adresse,
        MontDeuxCntCqt: this.MontDeuxCntCqt,
        MontDeuxCnt: this.MontDeuxCnt,
        MontCnt: this.MontCnt,
        MontCqt: this.MontCqt,
        photo: this.photo,
        MontVingtCinq: this.MontVingtCinq,
        MontDix: this.MontDix,
        MontCinq: this.MontCinq,
        TotalEncaise: this.TotalEncaise,
        ObservaTion: this.ObservaTion,
        MontCheqRecu: this.MontCheqRecu,
        MontCeqEmis: this.MontCeqEmis
      }
      this.$vs.loading({
        background: this.backgroundLoading,
        color: this.colorLoading
      })
      console.log(payload)

      setTimeout(() => {
        this.$vs.loading.close()
        this.$router.push('/apps/billetage/billetage-list')
      }, 1500)

    //   this.$vs.loading({
    //     type:'sound'
    //   })
      
      // le dispatch sera ici
    //   console.log("Form submitted!")
      // .then((res) => {
      //   console.log('success ajout billetage>', res)
      //   this.reset_data()
      // })
      // .catch(err => {
      //   console.error('erreur ajout billetage >',err)
      // })
      // .finally(() => {
      //   this.$vs.loading.close()
      //   this.$vs.notify({
      //     title: 'Client Ajouté !',
      //     text: 'success !!',
      //     iconPack: 'feather',
      //     icon: 'icon-check',
      //     color: 'success'
      //   })
      // })
    },
    reset_data () {
      this.IdOfficine = 
        this.IdUser =
        this.MontDixMil =
        this.MontCqMil =
        this.MontDeuxMil =
        this.DateCloture =
        this.MontMil =
        this.MontCqCnt =
        this.adresse =
        this.MontDeuxCntCqt =
        this.MontDeuxCnt =
        this.MontCnt =
        this.MontCqt =
        this.MontVingtCinq =
        this.MontDix =
        this.MontCinq =
        this.TotalEncaise =
        this.ObservaTion =
        this.MontCheqRecu =
        this.MontCeqEmis = ''
    }
  }

}
</script>
        